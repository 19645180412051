import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DoubleService {

  constructor(private http: HttpClient) { }

  getRoulette() {
    return this.http.post(`${environment.apiUrl}/user/getRoulette`, {});
  }

  create(token: string, amount: number, color: string) {
    return this.http.post(`${environment.apiUrl}/roulette/create`, {token: token, amount: amount, color: color});
  }

}