import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiceService {

  constructor(private http: HttpClient) { }

  create(token: string, chance: number, amount: number, side: string) {
    return this.http.post(`${environment.apiUrl}/dice/create`, {token: token, chance: chance, amount: amount, side: side});
  }

  game(token: string) {
    return this.http.post(`${environment.apiUrl}/dice/game`, {token: token});
  }
}