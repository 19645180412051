import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable(
  { providedIn: 'root' }
)
export class WebsocketService {
  socket:any;

  constructor() { 
    this.socket = io(environment.apiUrl);
  }

  listen(Eventname: string) {
    return new Observable((subscriber) => {
      this.socket.on(Eventname, (data) => {
        subscriber.next(data);
      });
    });
  }

  send(message: string, array: any) {
    return new Observable((subscriber) => {
      this.socket.emit(message, array);
      subscriber.next('success');
    });
  }

}