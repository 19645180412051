import { Component, OnInit, ViewChild } from '@angular/core';
@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }
}
