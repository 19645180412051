import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AppModule } from 'src/app/app.module';
import { AppComponent } from 'src/app/app.component';
import { NotifyService } from 'src/app/services/Notify/notify.service';
import { WebsocketService } from 'src/app/services/WebSocket/websocket.service';
import { DiceService } from 'src/app/services/Dice/dice.service';

@Component({
  selector: 'app-dice',
  templateUrl: './dice.component.html',
  styleUrls: ['./dice.component.css'],
})
export class DiceComponent implements OnInit {

  constructor(private main: AppComponent, module: AppModule, private cdr: ChangeDetectorRef, private notify: NotifyService, private srv: WebsocketService, private diceService: DiceService) { }

  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;

  @ViewChild('myIdentifier2')
  myIdentifier2: ElementRef;

  user = this.main.user;

  chance = 50;
  luckyNumber = '00';
  multiplicator = 1.92;
  roll = 'under';
  prediction = this.chance;

  animation = 'waiting';
  timer = 10;
  last_click = null;
  game = null;
  number = 1;
  value = 1.00;
  mines_number = 4;
  status = 1;
  scrollwidth = 0;
  scrollIdentifier = 0;
  positionDrag = 0;

  dragPosition = {x: 0, y:0}

  dragMoved($event) {
    
    if(this.roll == 'under') {
      var width = this.myIdentifier.nativeElement.clientWidth;
      this.scrollwidth = width;

      this.scrollIdentifier = this.myIdentifier2.nativeElement.clientWidth;

      this.positionDrag = (parseInt($event.source.getFreeDragPosition().x));
      let x = (parseInt($event.source.getFreeDragPosition().x)) ;
      let percent = (98.7 * x) / width;

      this.chance = percent + 1;
      this.multiplicator = parseFloat((((100 / (parseInt(this.chance.toFixed(0)) / 100)) * (1 - 0.025))/100).toFixed(3));
      this.prediction = this.chance;

    } else {
      var width = this.myIdentifier.nativeElement.clientWidth;
      this.scrollwidth = width;

      this.scrollIdentifier = this.myIdentifier2.nativeElement.clientWidth;

      this.positionDrag = (parseInt($event.source.getFreeDragPosition().x));
      let x = (parseInt($event.source.getFreeDragPosition().x)) ;
      let percent = (99 * x) / this.scrollwidth;
      
      this.chance = (percent + 4);
      this.multiplicator = parseFloat((((100 / (parseInt((99 - this.chance).toFixed(0)) / 100)) * (1 - 0.025))/100).toFixed(3));
      this.prediction = this.chance;
      this.positionDrag += 20;
    }
  }

  rollChange(type) {

    if(type == this.roll) return;
    
    this.roll = type;

    if(type == 'over') {
      this.positionDrag += 20;
      this.multiplicator = parseFloat((((100 / (parseInt((99 - this.chance).toFixed(0)) / 100)) * (1 - 0.025))/100).toFixed(3));
      this.chance += 3;
    } else {
      this.positionDrag -= 20;
      this.multiplicator = parseFloat((((100 / (parseInt(this.chance.toFixed(0)) / 100)) * (1 - 0.025))/100).toFixed(3));
      this.chance -= 3;
    }
  }

  ngAfterViewInit() {
    var width = this.myIdentifier.nativeElement.clientWidth;
    this.scrollwidth = width;
    this.scrollIdentifier = this.myIdentifier2.nativeElement.clientWidth;
    this.dragPosition.x = this.myIdentifier.nativeElement.clientWidth/2;
    this.positionDrag = this.myIdentifier.nativeElement.clientWidth/2;
    this.cdr.detectChanges();
  }

  ngOnInit() {
    
    this.main.game = 'dice';

    /*this.diceService.game(this.user.token).subscribe((res:any) => {
      if(res) {
        if(res.status == 'success') {
          this.game = res.game;
          console.log(res.game)
        } else {

        }
      }
    });*/

  }

  inputAmount(type) {
    if(type == 'min') this.value = 0.10;
    if(type == 'max') this.value = this.user.balance;
    if(type == '2x') {
      if((this.value * 2) > this.user.balance) {
        this.value = this.user.balance;
      } else {
        this.value = parseFloat((this.value*2).toFixed(2));
      }
    }
    if(type == '1/2') {
      if((this.value/2) < 0.1) {
        this.value = 0.10;
      } else {
        this.value = parseFloat((this.value/2).toFixed(2));
      }
    }
  }

  onKey(event: any) { // without type info
    let amount = parseFloat(event.target.value);
    if(amount >= 0) {
      this.value = amount;
    } else {
      this.value = 0.01;
    }

  }


  betDice() {
    if(this.value >= 0.1) {
      if(this.animation == 'rolling') return;
      this.diceService.create(this.user.token, this.chance, this.value, this.roll).subscribe((res:any) => {
        if(res) {
          if(res.status == 'success') {
            console.log(res);
            this.user.balance -= this.value;
            localStorage.setItem('user', JSON.stringify(this.user));

            this.animation = 'rolling';
            if(res.type == 'win') {
              setTimeout(() => {
                this.user.balance += parseFloat(res.game.reward);
                localStorage.setItem('user', JSON.stringify(this.user));
                this.luckyNumber = res.game.result;
                this.animation = 'waiting';
              }, 600);
            } else {
              setTimeout(() => {
                this.luckyNumber = res.game.result;
                this.animation = 'waiting';
              }, 600);
            }
          
          } else {

          }
        }
      });
    } else {
      this.notify.showError('Valor mínimo por aposta de 0.1R$', 'Error!');
    }
  }


}
