import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-crash',
  templateUrl: './crash.component.html',
  styleUrls: ['./crash.component.css']
})
export class CrashComponent {
  @ViewChild('crashCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;

  public ctx: CanvasRenderingContext2D;

  rocket_image = new Image();
  rocket_animation = {
    maxWidth: 430,
    marginTop: 430,
    width: 70,
    height: 70,
    image: 1
  }



  ngAfterViewInit(): void {

    this.ctx = this.myCanvas.nativeElement.getContext('2d');
    this.ctx.moveTo(0,0);
    this.ctx.lineTo(250,250);
    this.ctx.stroke();

    this.startCrashAnimation();
  }

  startCrashAnimation() {

    let max = 430;
    let right = 0;
    let up = 430;
    setInterval(() => {
      this.rocket_image.src = '../../../assets/img/rocket' + this.rocket_animation.image + '.png';
      this.rocket_animation.image ++;
  
      if(this.rocket_animation.image >= 4) this.rocket_animation.image = 1;
      this.ctx.clearRect(0, 0, 500, 500);
      this.ctx.drawImage(this.rocket_image, right, up, 70, 70);
      if(right + 5 < max) right += 5;
      if(up - 5 > 0) up-=5;
    }, 60)
  }


  
}
