import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { AppModule } from 'src/app/app.module';
import { AppComponent } from 'src/app/app.component';
import { NotifyService } from 'src/app/services/Notify/notify.service';
import { WebsocketService } from 'src/app/services/WebSocket/websocket.service';
import { MinesService } from 'src/app/services/Mines/mines.service';

@Component({
  selector: 'app-mines',
  templateUrl: './mines.component.html',
  styleUrls: ['./mines.component.css']
})
export class MinesComponent implements OnInit {

  constructor(private main: AppComponent, module: AppModule, private notify: NotifyService, private srv: WebsocketService, private minesGame: MinesService) { }

  user = this.main.user;

  timer = 10;
  last_click = null;
  game = null;
  number = 1;
  value = 1.00;
  mines_number = 4;
  status = 1;
  mines = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
  minesMultiplicatorTable = {
    1: {1 : 1.03, 2 : 1.04, 3 : 1.05, 4 : 1.05, 5 : 1.06, 6 : 1.07, 7 : 1.07, 8 : 1.08, 9 : 1.09, 10 : 1.11, 11 : 1.12, 12 : 1.14, 13 : 1.16, 14 : 1.19, 15 : 1.23, 16 : 1.28, 17 : 1.34, 18 : 1.44, 19 : 1.59, 20 : 1.82, 21 : 2.24, 22 : 3.06, 23 : 5.12, 24 : 23.75},
    2: {1 : 1.03, 2 : 1.12, 3 : 1.23, 4 : 1.35, 5 : 1.5, 6 : 1.66, 7 : 1.86, 8 : 2.09, 9 : 2.37, 10 : 2.71, 11 : 3.13, 12 : 3.65, 13 : 4.31, 14 : 5.18, 15 : 6.33, 16 : 7.91, 17 : 10.17, 18 : 13.57, 19 : 19, 20 : 28.5, 21 : 47.5, 22 : 95, 23 : 285},
    3 : {1 : 1.07, 2 : 1.23, 3 : 1.41, 4 : 1.64, 5 : 1.91, 6 : 2.25, 7 : 2.67, 8 : 3.21, 9 : 3.9, 10 : 4.8, 11 : 6, 12 : 7.63, 13 : 9.93, 14 : 13.24, 15 : 18.2, 16 : 26.01, 17 : 39.01, 18 : 62.42, 19 : 109.25, 20 : 218.2, 21 : 546.25, 22 : 2190},
    4 : {1 : 1.13, 2 : 1.35, 3 : 1.64, 4 : 2, 5 : 2.48, 6 : 3.1, 7 : 3.92, 8 : 5.04, 9 : 6.6, 10 : 8.8, 11 : 12, 12 : 16.8, 13 : 24.77, 14 : 36.41, 15 : 57.22, 16 : 95.37, 17 : 171.67, 18 : 343.55, 19 : 801.16, 20 : 2400, 21 : 12020},
    5 : {1 : 1.18, 2 : 1.5, 3 : 1.91, 4 : 2.48, 5 : 3.25, 6 : 4.34, 7 : 5.89, 8 : 8.15, 9 : 11.55, 10 : 16.8, 11 : 25.21, 12 : 39.21, 13 : 63.72, 14 : 109.25, 15 : 200.29, 16 : 400.58, 17 : 901.31, 18 : 2400, 19 : 8410, 20 : 50470},
    6 : {1 : 1.25, 2 : 1.66, 3 : 2.25, 4 : 3.1, 5 : 4.34, 6 : 6.2, 7 : 9.06, 8 : 13.59, 9 : 21, 10 : 33.61, 11 : 56.02, 12 : 98.04, 13 : 182.08, 14 : 364.16, 15 : 801.16, 16 : 2000, 17 : 6010, 18 : 24040, 19 : 168000},
    7 : {1 : 1.31, 2 : 1.86, 3 : 2.67, 4 : 3.92, 5 : 5.89, 6 : 9.06, 7 : 14.34, 8 : 23.48, 9 : 39.91, 10 : 70.96, 11 : 133.06, 12 : 266.12, 13 : 576.59, 14 : 1380, 15 : 3810, 16 : 12690, 17 : 57080, 18 : 457000},
    8 : {1 : 1.39, 2 : 2.09, 3 : 3.21, 4 : 5.04, 5 : 8.15, 6 : 13.59, 7 : 23.48, 8 : 42.26, 9 : 79.83, 10 : 159.67, 11 : 342.15, 12 : 798.36, 13 : 2080, 14 : 6230, 15 : 22830, 16 : 114000, 17 : 1030000},
    9 : {1 : 1.48, 2 : 2.37, 3 : 3.9, 4 : 6.6, 5 : 11.55, 6 : 21, 7 : 39.91, 8 : 79.83, 9 : 169.65, 10 : 387.77, 11 : 396.44, 12 : 2710, 13 : 8820, 14 : 35290, 15 : 194000, 16 : 1940000},
    10 : {1 : 1.58, 2 : 2.71, 3 : 4.8, 4 : 8.8, 5 : 16.8, 6 : 33.61, 7 : 70.96, 8 : 159.67, 9 : 387.77, 10 : 1030, 11 : 3100, 12 : 10860, 13 : 47050, 14 : 282000, 15 : 3100000},
    11 : {1 : 1.69, 2 : 3.13, 3 : 6, 4 : 12, 5 : 25.21, 6 : 56.02, 7 : 133.06, 8 : 342.15, 9 : 969.44, 10 : 3100, 11 : 11630, 12 : 54290, 13 : 353000, 14 : 4230000},
    12 : {1 : 1.82, 2 : 3.65, 3 : 7.63, 4 : 16.8, 5 : 39.21, 6 : 98.04, 7 : 266.12, 8 : 768.36, 9 : 2710, 10 : 10860, 11 : 54290, 12 : 380000, 13 : 4940000},
    13 : {1 : 1.97, 2 : 4.31, 3 : 9.93, 4 : 24.27, 5 : 63.72, 6 : 182.08, 7 : 576.59, 8 : 20800, 9 : 8820, 10 : 47050, 11 : 353000, 12 : 4940000},
    14 : {1 : 2.15, 2 : 5.18, 3 : 13.24, 4 : 36.41, 5 : 109.25, 6 : 364.16, 7 : 1380, 8 : 6230, 9 : 35290, 10 : 282000, 11 : 4230000},
    15 : {1 : 2.37, 2 : 6.33, 3 : 18.2, 4 : 57.22, 5 : 200.29, 6 : 801.16, 7 : 3810, 8 : 22830, 9 : 194000, 10 : 3110000},
    16 : {1 : 2.63, 2 : 7.91, 3 : 26.01, 4 : 95.37, 5 : 400.58, 6 : 2000, 7 : 12690, 8 : 114000, 9 : 1940000},
    17 : {1 : 2.96, 2 : 10.17, 3 : 39.01, 4 : 171.67, 5 : 901.31, 6 : 6010, 7 : 57080, 8 : 1030000},
    18 : {1 : 3.39, 2 : 13.57, 3 : 62.42, 4 : 343.35, 5 : 2400, 6 : 24030, 7 : 457000},
    19 : {1 : 3.95, 2 : 19, 3 : 109.25, 4 : 801.16, 5 : 8410, 6 : 168000},
    20 : {1 : 4.75, 2 : 28.5, 3 : 218.5, 4 : 2400, 5 : 50470},
    21 : {1 : 5.93, 2 : 47.5, 3 : 546.25, 4 : 12020},
    22 : {1 : 7.91, 2 : 95, 3 : 2190},
    23 : {1 : 11.87, 2 : 285},
    24 : {1 : 23.75}
  }

  ngOnInit() {

    this.minesGame.game(this.user.token).subscribe((res:any) => {
      if(res) {
        if(res.status == 'success') {
          this.game = res.game;
          console.log(res.game)
        } else {

        }
      }
    });

  }

  optionChange(value) {
    if(value >= 0.01) {
      this.value = value;
    } else {
      this.value = 0.00;
    }
  }

  getColor(number) {
    if(number == 0) return 'white';
    if(number == 11 || number == 10 || number == 9 || number == 8 || number == 14 || number == 13 || number == 12) return 'black';
    return 'red';

  }

  inputAmount(type) {
    if(type == 'min') this.value = 0.10;
    if(type == 'max') this.value = this.user.balance;
    if(type == '2x') {
      if((this.value * 2) > this.user.balance) {
        this.value = this.user.balance;
      } else {
        this.value = parseFloat((this.value*2).toFixed(2));
      }
    }
    if(type == '1/2') {
      if((this.value/2) < 0.1) {
        this.value = 0.10;
      } else {
        this.value = parseFloat((this.value/2).toFixed(2));
      }
    }
  }

  inputMine(type) {
    if(parseInt(type) > 0 && parseInt(type) < 24) {
      this.mines_number = parseInt(type);
    } else if (type == '+'){
      if(this.mines_number + 1 > 24) {
        this.mines_number = 24;
      } else {
        this.mines_number += 1;
      }
      
    } else if (type == '-'){
      if(this.mines_number - 1 <= 0) {
        this.mines_number = 1;
      } else {
        this.mines_number -= 1;
      }
    }
  }

  onKey(event: any) { // without type info
    let amount = parseFloat(event.target.value);
    if(amount >= 0) {
      this.value = amount;
    } else {
      this.value = 0.01;
    }

  }

  onKeyChangeMines(event: any) { // without type info
    let amount = parseInt(event.target.value);
    if(amount > 0) {
      this.mines_number = amount;
    } else {
      this.mines_number = 1;
    }

  }

  clickTile(id) {
    if(this.game != null) {
      if(this.game.status == 1) {
        this.last_click = id;
        this.minesGame.click(this.user.token, id).subscribe((res:any) => {
          if(res) {
            if(res.status == 'success') {
              if(res.type == 'win') {
                console.log('win')
                this.game.diamonds = res.diamonds;
              } else if(res.type == 'lose') {
                console.log('lose')
                this.game = res.game;
              }
            } else if(res.status == 'error') {

            }
          } else {
          
          }
        });
      } else {

      }
    } else {
      this.notify.showSuccess('Erro', 'Inicie um jogo antes disso.')
    }
  }


  betMines() {
    if(this.value >= 0.1) {
      this.minesGame.create(this.user.token, this.value, this.mines_number).subscribe((res:any) => {
        if(res) {
          console.log(res)
          if(res.status == 'success') {
            this.game = res.game;
            this.last_click = null;
            this.user.balance -= this.value;
            localStorage.setItem('user', JSON.stringify(this.user));
          } else {

          }

        }
      });
    } else {
      this.notify.showError('Valor mínimo por aposta de 0.1R$', 'Error!');
    }
  }

  minesCashout() {
    if(this.game != null) {
      if(this.game.status == 1) {
        this.minesGame.cashout(this.user.token).subscribe((res:any) => {
          if(res) {
            if(res.status == 'success') {
              this.user.balance += res.reward;
              localStorage.setItem('user', JSON.stringify(this.user));

              this.game = res.game;
              this.game.status = 2;
              console.log(res)
            } else {
 
            }
          }
        });
      } else {

      }
    }
  }


}
