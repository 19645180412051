import { Injectable } from '@angular/core';
  
import { ToastrService } from 'ngx-toastr';
  
@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  
  constructor(private toastr: ToastrService) {

  }
  
  showSuccess(message, title){
      this.toastr.success(message, title, {
        enableHtml: true,
        closeButton: true,
        tapToDismiss: false
      });
  }
  
  showError(message, title){
      this.toastr.error(message, title, {
        enableHtml: true,
        closeButton: true,
        tapToDismiss: false
      });
  }
  
  showInfo(message, title){
      this.toastr.info(message, title, {
        disableTimeOut: true,
        enableHtml: true,
        closeButton: true,
        tapToDismiss: false
      });
  }
  
  showWarning(message, title){
      this.toastr.warning(message, title, {
        disableTimeOut: true,
        enableHtml: true,
        closeButton: true,
        tapToDismiss: false
      });
  }
  
}