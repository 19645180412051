import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MinesService {

  constructor(private http: HttpClient) { }

  create(token: string, amount: number, bombs: number) {
    return this.http.post(`${environment.apiUrl}/mines/create`, {token: token, amount: amount, bombs: bombs});
  }

  click(token: string, id: number) {
    return this.http.post(`${environment.apiUrl}/mines/click`, {token: token, id: id});
  }

  cashout(token: string) {
    return this.http.post(`${environment.apiUrl}/mines/cashout`, {token: token});
  }

  game(token: string) {
    return this.http.post(`${environment.apiUrl}/mines/game`, {token: token});
  }
}