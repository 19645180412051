import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { AppComponent } from 'src/app/app.component';
import { NotifyService } from 'src/app/services/Notify/notify.service';
import { WebsocketService } from 'src/app/services/WebSocket/websocket.service';
import { DoubleService } from 'src/app/services/Double/double.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-double',
  templateUrl: 'double.component.html',
  styleUrls: ['double.component.css']
})
export class DoubleComponent implements OnInit {

  menuAberto = false;

  toggleMenu() {
    this.menuAberto = !this.menuAberto;
  }

  constructor(private main: AppComponent, private location: Location, private srv: WebsocketService, private roulette: DoubleService) { }

  user = {
    username: '',
    balance: 0,
    user_id: '',
    currency: '',
    lang: ''
  };

  notify = {
    active: false,
    error: false,
    message: "",
    errorMessage: (message) => {
      this.notify.message = message;
      this.notify.error = true;
      this.notify.active = true;
      this.notify.timerFadeOut(3);
    },
    successMessage: (message) => {
      this.notify.message = message;
      this.notify.error = false;
      this.notify.active = true;
      this.notify.timerFadeOut(3);
    },
    timerFadeOut: (seconds) => {
      setTimeout(() => {
        this.notify.active = false;
      }, 1000 * seconds);
    }
  }

  connection = false;
  timer = 10;
  number = 1;
  session = null;
  history = [];
  animation = 10.00;
  value = 1.00;
  status = 1;
  bets = [];
  numbers = [];
  color = 'white';
  red = { total: 0, bets: [] };
  white = { total: 0, bets: [] };
  black = { total: 0, bets: [] };

  dividirPorDois() {
    if(this.value !> 1)
      this.value = this.value / 2;
  }

  multiplicarPorDois() {
    this.value = this.value * 2;
  }

  info = {
    user: null,
    token: null,
    lang: null,
    currency: null,
    operator: null,
    return_url: null,
    game: 'doublefire'
  }

  ngOnInit() {
    try {
      let data = this.location.path().replace('/', '');
      data = data.split('?')[1];
      
      let params = data.split('&');
      params.forEach((param) => {
        let cut = param.split('=');
        if(this.info[cut[0]] == null) this.info[cut[0]] = cut[1];
      });

      this.srv.send('doublefire new user', this.info).subscribe((res:any) => {

      });
    } catch(e) {

    }

    this.roulette.getRoulette().subscribe(data => {
      this.numbers = data['results'];
      this.status = data['status'];
      data['history'].forEach( game => {
        if(this.history.length < 10) {
          this.history.unshift(game);
        }
      });

    });

    this.srv.listen('success connection').subscribe((res:any) => {

      if(res.status == 'success') {
        this.user = res.data;
        this.session = res.data.session;
        setTimeout(() => {
          this.connection = true;
        }, 1000);
      }
    });

    this.srv.listen('roulette timer').subscribe((res:any) => {
      this.timer = res['timer'];
      this.status = res['status'];
      if(res.status == 2) {
        this.timer = 10;
        $('.double .scroll').css('background-image', 'repeating-linear-gradient( -45deg, transparent, transparent 4px, #2f3445 4px, #2f3445 8px )');
        $('.double .scroll').css('background-size', '200% 200%');
        $('.double .scroll').css('animation', '30s linear 0s infinite normal none running slide-background');
        $('.double .scroll').css('background-color', 'transparent');
        $('.double .scroll').css('position', 'absolute');
        $('.double .scroll').css('width', '10000px');
      } else if(res.status == 1) {
        $('.double .scroll').css('background-image', '');
        $('.double .scroll').css('background-size', '');
        $('.double .scroll').css('animation', '');
        $('.double .scroll').css('background-color', 'var(--red)');
        $('.double .scroll').css('position', 'relative');
      }
    });

    this.srv.listen('rolling animation').subscribe((res:any) => {
      this.animation = res;
    });

    this.srv.listen('error roulette').subscribe((res:any) => {
      let email = res['user'];
      let error = res['error'];

      if(error == 'money') {
        this.notify.errorMessage('Valor mínimo por apostas de 0.01R$');
      } else if(error == 'betting') {
        this.notify.errorMessage('Espere a próxima rodada para apostar.');
      }
    });

    this.srv.listen('error message').subscribe((res:any) => {
      this.notify.errorMessage(res.message);
    });

    this.srv.listen('new roulette bet').subscribe((res:any) => {
      res = JSON.parse(res);
      this.red.bets = [];
      this.white.bets = [];
      this.black.bets = [];
      this.red.total = 0;
      this.white.total = 0;
      this.black.total = 0;

      res.forEach(game => {
        if(game.color == 'white') {
          this.white.bets.push(game);
          this.white.total += game.amount;
        } else if(game.color == 'red') {
          this.red.bets.push(game);
          this.red.total += game.amount;
        } else if(game.color == 'black') {
          this.black.bets.push(game);
          this.black.total += game.amount;
        }
      });

      this.red.bets.sort(function(a, b) {
        return b.amount - a.amount;
      });

      this.white.bets.sort(function(a, b) {
        return b.amount - a.amount;
      });

      this.black.bets.sort(function(a, b) {
        return b.amount - a.amount;
      });

    });

    this.srv.listen('roulette numbers').subscribe((res:any) => {
      this.numbers = res['results'];
      this.history.unshift(res['history'][res['history'].length - 1]);
      if(this.history.length > 10) {
        this.history.pop();
      }
    });

    this.srv.listen('betfire update wallet').subscribe((res:any) => {
      this.user.balance = res.balance;
    });

    this.srv.listen('betfire confirm bet').subscribe((res:any) => {
      if(res.status == 'success') {
        let color = 'branco';
        if(this.color == 'red') color = 'vermelho';
        if(this.color == 'black') color = 'preto';

        this.user.balance = res.data.new_balance;
      
        // this.notify.showSuccess('Você apostou ' + this.value + 'R$ no ' + color, 'Success!')
      }
    });

    this.srv.listen('roulette roll').subscribe((res:any) => {
      let pickerX = res.transition;
      let random = res.random;

      $('.picker').css('transition-duration', '5000ms');
      $('.picker').css('transform', 'translateX(-' + ((2178 + ((104 * 15) * 4)) + pickerX ) + 'px)');

      setTimeout(test => {

        this.status = 3;

        this.number = res.number;
        $('.picker').css('transition-duration', '10ms');
        $('.picker').css('transform', 'translateX(-' + ((2178 + ((104 * 15) * 4)) + (pickerX - random) ) + 'px)');
        setTimeout((res:any) => {
          setTimeout((res:any) => {
            $('.picker').css('transition-duration', '1000ms');
            $('.picker').css('transform', 'translateX(-2178px)');
            this.number = 1;
            this.red.bets = [];
            this.white.bets = [];
            this.black.bets = [];
            this.red.total = 0;
            this.white.total = 0;
            this.black.total = 0;
          }, 2100);
        }, 3000);
      }, 6000);
    });
  }

  optionChange(value) {
    if(value >= 0.01) {
      this.value = value;
    } else {
      this.value = 0.00;
    }
  }

  getColor(number) {
    if(number == 0) return 'white';
    if(number == 11 || number == 10 || number == 9 || number == 8 || number == 14 || number == 13 || number == 12) return 'black';
    return 'red';

  }

  changeColour(color) {
    this.color = color;
  }

  onKey(event: any) { // without type info
    let amount = parseFloat(event.target.value);
    if(amount >= 0) {
      this.value = amount;
    } else {
      this.value = 0.01;
    }

  }

  betRoulette() {
    if(this.value > 0.01) {
      this.srv.send('doublefire new bet', {
        user_id: this.user.user_id, username: this.user.username, session_token: this.session, currency: this.user.currency, amount: this.value, color: this.color
      }).subscribe((res:any) => {
        // this.notify.successMessage('Aposta aceita');
      });
    } else {
      this.notify.errorMessage('Valor mínimo por apostas de 0.01R$');
    }
  }


}
