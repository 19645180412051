import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DoubleComponent } from './components/double/double.component';
import { IntroComponent } from './components/intro/intro.component';
import { CrashComponent } from './components/crash/crash.component';
import { MinesComponent } from './components/mines/mines.component';
import { DiceComponent } from './components/dice/dice.component';


const routes: Routes = [
  { path: '', component: DoubleComponent, pathMatch: 'full' },
  { path: 'doublefire', component: DoubleComponent },
  { path: 'crash', component: CrashComponent },
  { path: 'dice', component: DiceComponent },
  { path: 'mines', component: MinesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
