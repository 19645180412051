import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragScrollModule } from 'ngx-drag-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from './components/intro/intro.component';
import { MinesComponent } from './components/mines/mines.component';
import { DiceComponent } from './components/dice/dice.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from "@angular/material/dialog";
import { DoubleComponent } from './components/double/double.component';
import { CrashComponent } from './components/crash/crash.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatSliderModule} from '@angular/material/slider';

@NgModule({
  declarations: [
    AppComponent,
    MinesComponent,
    DiceComponent,
    IntroComponent,
    CrashComponent,
    DoubleComponent
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    DragScrollModule,
    MatSliderModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    MatDialogModule,
    ToastrModule.forRoot()
  ],
  exports: [
    NgxMaskModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
