import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {

  user = JSON.parse(localStorage.getItem('user'));
  market = [];
  block = false;
  game = 'other';
  page = '';
  mobileMenu = false;
  profileMenu = false;

  title = 'Gamesbet';
  
  constructor(private dialog: MatDialog) {

    if(this.user != null) {
      console.log(this.user)
    }
  }

  openDialog() {

    const dialogConfig = new MatDialogConfig();

  }

  registerModal() {

    const dialogConfig = new MatDialogConfig();

  }

  loginModal() {


  }


  refresh() {
    window.location.reload();
  }
  

  ngOnInit() {
  }

}
