
<div class="content flex flex-row">
    <div class="mines mt-2">
        <div class="games-top">
            <div class="games-info">
                <span>Mines</span><i class="fa fa-info-circle"></i>
            </div>

            <div class="sound"><svg class="" t="1666255780803" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3342" width="18" height="18"><path d="M128 420.576v200.864h149.12l175.456 140.064V284.288l-169.792 136.288H128z m132.256-64l204.288-163.968a32 32 0 0 1 52.032 24.96v610.432a32 32 0 0 1-51.968 24.992l-209.92-167.552H96a32 32 0 0 1-32-32v-264.864a32 32 0 0 1 32-32h164.256zM670.784 720.128a32 32 0 0 1-44.832-45.664 214.08 214.08 0 0 0 64.32-153.312 213.92 213.92 0 0 0-55.776-144.448 32 32 0 1 1 47.36-43.04 277.92 277.92 0 0 1 72.416 187.488 278.08 278.08 0 0 1-83.488 198.976zM822.912 858.88a32 32 0 1 1-45.888-44.608A419.008 419.008 0 0 0 896 521.152c0-108.704-41.376-210.848-114.432-288.384a32 32 0 0 1 46.592-43.872c84.16 89.28 131.84 207.04 131.84 332.256 0 127.84-49.76 247.904-137.088 337.728z" p-id="3343" fill="#ffffff"></path></svg></div>
        </div>
        <div class="game flex flex-column">
            <div class="mines-top">
                <div class="mines-info-mobile">
                    <div class="diamonds-info">
                        <span>Diamonds</span>
                        <img src="../../../assets/img/mines/crystal.png" alt="" width="50px">
                        <span>{{ 25 - mines_number}}</span>
                    </div>
                    <div class="mines-info">
                        <span>Mines</span>
                        <img src="../../../assets/img/mines/bomb.png" alt="" width="50px">
                        <span>{{mines_number}}</span>
                    </div>
                </div>
                <div class="diamonds-info mobile-remove">
                    <span>Diamonds</span>
                    <img src="../../../assets/img/mines/crystal.png" alt="" width="50px">
                    <span>{{ 25 - mines_number}}</span>
                </div>
                <div class="bombsweeper">
                    <ng-container *ngFor="let mine of mines">
                        <div *ngIf="game == null" class="tile-bomber" data-id="{{ mine }}" (click)="clickTile(mine)">
                            <div class="tile-text">?</div>
                        </div>
                        <div *ngIf="game != null" [ngStyle]="{'background-color': (last_click != null && last_click == mine) ? 'rgba(20, 82, 159)' : ''}" [ngClass]="{'lose': game.mines.includes(mine), 'win': game.diamonds.includes(mine)}" class="tile-bomber" data-id="{{ mine }}" (click)="clickTile(mine)">
                            <div *ngIf="!game.mines.includes(mine) && !game.diamonds.includes(mine)" class="tile-text">?</div>
                            <div *ngIf="game.mines.includes(mine)" class="tile-click"><img src="../../../assets/img/mines/bomb.png" width="100%" height="100%"></div>
                            <div *ngIf="game.diamonds.includes(mine)" class="tile-click"><img src="../../../assets/img/mines/crystal.png" width="100%" height="100%"></div>
                            
                        </div>
                    </ng-container>
                </div>
                <div class="mines-info mobile-remove">
                    <span>Mines</span>
                    <img src="../../../assets/img/mines/bomb.png" alt="" width="50px">
                    <span>{{mines_number}}</span>
                </div>
            </div>
            <div class="multipliers">
                <div class="leftArrow">
                    <i class="fa fa-chevron-left"></i>
                </div>
                <div class="list">
                    
                    <ng-container *ngFor="let mine of mines">
                        
                        <div class="list-item flex" data-id="{{ mine }}" *ngIf="mine <= (25 - mines_number)">
                            <div class="sub-item">
                                <div class="item-multiplier">{{minesMultiplicatorTable[mines_number][mine]}}x</div>
                                <div class="item-hits">
                                    {{mine}} hit
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="rightArrow">
                    <i class="fa fa-chevron-right"></i>
                </div>
            </div>
           <form class="wager">
                <div class="bet">
                    <div class="bet-controll">
                        <div class="button-controll" (click)="inputAmount('min')">Min</div>
                        <div class="button-controll" (click)="inputAmount('max')">Max</div>
                    </div>
                    <div class="input-info">
                        <span>R$</span> <input thousandSeparator="." (keyup)="onKey($event)" type="number" [value]="value" class="coins" placeholder="0.00">
                    </div>
                    <div class="bet-controll">
                        <div class="button-controll" (click)="inputAmount('1/2')">1/2</div>
                        <div class="button-controll" (click)="inputAmount('2x')">2x</div>
                    </div>
                </div>
                <div class="confirm-bet" (click)="betMines()" *ngIf="game == null">
                    APOSTAR
                </div>
                <div class="confirm-bet" (click)="betMines()" *ngIf="game != null && game.status == 2">
                    NOVO JOGO
                </div>
                <div class="confirm-bet" *ngIf="game != null && game.status != 2" (click)="minesCashout()">
                    <span *ngIf="game.diamonds.length == 0">RETIRAR ( {{ game.amount }} )</span>
                    <span *ngIf="game.diamonds.length != 0">RETIRAR ( {{ game.amount * minesMultiplicatorTable[game.bombs][game.diamonds.length] }} )</span>
                </div>
            </form>

            <div class="mines-chose">
                <div class="mine-chose" (click)="inputMine(1)">1</div>
                <div class="mine-chose" (click)="inputMine(2)">2</div>
                <div class="mine-chose" (click)="inputMine(3)">3</div>
                <div class="mine-chose" (click)="inputMine(4)">4</div>
                <div class="mine-chose" (click)="inputMine(5)">5</div>
                <div class="mines-input">
                    <div class="less button-controll" (click)="inputMine('-')">-</div>
                    <input thousandSeparator="." (keyup)="onKeyChangeMines($event)" type="number" [value]="mines_number" class="coins" placeholder="0.00">
                    <div class="plus button-controll" (click)="inputMine('+')">+</div>
                </div>
                <div class="mine-chose" (click)="inputMine(6)">6</div>
                <div class="mine-chose" (click)="inputMine(7)">7</div>
                <div class="mine-chose" (click)="inputMine(8)">8</div>
                <div class="mine-chose" (click)="inputMine(9)">9</div>
                <div class="mine-chose" (click)="inputMine(10)">10</div>
            </div>
    
        </div>
    </div>
    <div class="live-history">
        <div class="live-top flex flex-row">
            <div>Usuário</div>
            <div>Multiplicador</div>
            <div>Pagar</div>
        </div>
        <div>

        </div>
    </div>
</div>
